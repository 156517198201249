import { Container } from "@mui/material";
import React from "react";
import ComingSoon from "../components/ComingSoon/ComingSoon";
import Header from "../components/Header";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer/Footer";

const Articles = () => {
  return (
    <>
      <Helmet>
        <title>Articles | Yella</title>
      </Helmet>
      <Header isTransparent />
      <Container maxWidth='lg'>
        <ComingSoon />
      </Container>
      <Footer />
    </>
  );
};

export default Articles;
